/** *************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';
/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/guide/browser-support
 */
/** *************************************************************************************************
 * BROWSER POLYFILLS
 */
import 'core-js/es/typed-array'; // Run `npm install --save classlist.js`.
/** Evergreen browsers require these. **/
import 'core-js/es/reflect'; // Run `npm install --save web-animations-js`.
/** *************************************************************************************************
 * Zone JS is required by Angular itself.
 */
import 'zone.js/dist/zone'; // Included with Angular CLI.

/** *************************************************************************************************
 * APPLICATION IMPORTS
 */

/**
 * Date, currency, decimal and percent pipes.
 * Needed for: All but Chrome, Firefox, Edge, IE11 and Safari 10
 */
// import 'intl';  // Run `npm install --save intl`.
/**
 * Need to import at least one locale-data with intl.
 */
// import 'intl/locale-data/jsonp/en';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).global = window;

if (!Array.prototype.includes) {
  Object.defineProperty(Array.prototype, 'includes', {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: function (searchElement: any, fromIndex: number): boolean {
      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      const o = Object(this);
      const len = o.length >>> 0;

      if (len === 0) {
        return false;
      }

      const n = fromIndex | 0;

      let k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      function sameValueZero(x: any, y: any): boolean {
        return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y));
      }

      while (k < len) {
        if (sameValueZero(o[k], searchElement)) {
          return true;
        }

        k++;
      }

      return false;
    },
  });
}
